<script setup lang="ts">
import { computed, resolveComponent } from 'vue'

const props = withDefaults(defineProps<{
  icon: string
  theme?: keyof typeof themes
  size?: keyof typeof sizes
  to?: string
  href?: string
  target?: '_blank' | '_self' | '_parent' | '_top'
}>(), {
  theme: 'flat',
  size: 's',
})

const themes = {
  flat: {
    button: 'border border-transparent hover:border-slate-200',
    div: 'text-slate-200 hover:text-slate-100',
  },
  primary: {
    button: 'bg-gradient-to-br from-lemon to-sunset shadow-md hover:from-lemon-400 hover:to-sunset-400 hover:shadow-xl',
    div: 'text-white',
  },
}

const sizes = {
  s: {
    button: 'p-4',
    div: 'h-5 w-5',
  },
  xl: {
    button: 'p-2',
    div: 'h-7 w-7',
  },
}

const buttonClasses = computed(() => {
  return `${themes[props.theme].button} ${sizes[props.size].button}`
})

const divClasses = computed(() => {
  return `${themes[props.theme].div} ${sizes[props.size].div}` + ` ${props.icon}`
})

const componentTag = computed(() => {
  if (props.href)
    return 'a'
  if (props.to)
    return resolveComponent('NuxtLink')
  return 'button'
})
</script>

<template>
  <component
    :is="componentTag"
    :class="buttonClasses"
    class="inline-flex rounded-full transition-all"
    v-bind="{
      ...to ? { to } : undefined,
      ...href ? { href, target } : undefined,
    }"
  >
    <div :class="divClasses" class="transition-all" />
  </component>
</template>
